import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
  } from "@material-tailwind/react";
   
  export function Langage() {
    return (
     <Menu
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
      >
        <MenuHandler>
          <Button variant="text" className=" flex items-center space-x-2" > 
            <svg width="22" height="22" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M176 436H16V76H176L186 246.001L176 436Z" fill="#0053B5"/>
                <path d="M496 436H336L326 256L336 76H496V436Z" fill="#D80027"/>
                <path d="M176 76H336V436H176V76Z" fill="white"/>
            </svg>
            <p>FR</p>
          </Button>
        </MenuHandler>
        <MenuList>
          <MenuItem className=" flex items-center space-x-2">
            <svg width="22" height="22" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 85.3311H512V426.668H0V85.3311Z" fill="#F0F0F0"/>
            <path d="M0 127.994H512V170.657H0V127.994ZM0 213.331H512V255.994H0V213.331ZM0 298.657H512V341.32H0V298.657ZM0 383.994H512V426.657H0V383.994Z" fill="#D80027"/>
            <path d="M0 85.3311H256V269.128H0V85.3311Z" fill="#2E52B2"/>
            <path d="M99.822 160.624L95.699 173.308H82.363L93.154 181.143L89.031 193.826L99.822 185.991L110.606 193.826L106.484 181.143L117.275 173.308H103.938L99.822 160.624ZM103.938 219.08L99.822 206.397L95.699 219.08H82.363L93.154 226.916L89.031 239.599L99.822 231.763L110.606 239.599L106.484 226.916L117.275 219.08H103.938ZM47.577 219.08L43.46 206.397L39.337 219.08H26.001L36.792 226.916L32.669 239.599L43.46 231.763L54.245 239.599L50.123 226.916L60.912 219.08H47.577ZM43.46 160.624L39.337 173.308H26.001L36.792 181.143L32.669 193.826L43.46 185.991L54.245 193.826L50.123 181.143L60.912 173.308H47.577L43.46 160.624ZM99.822 114.85L95.699 127.535H82.363L93.154 135.371L89.031 148.054L99.822 140.218L110.606 148.054L106.484 135.371L117.275 127.535H103.938L99.822 114.85ZM43.46 114.85L39.337 127.535H26.001L36.792 135.371L32.669 148.054L43.46 140.218L54.245 148.054L50.123 135.371L60.912 127.535H47.577L43.46 114.85ZM156.183 160.624L152.061 173.308H138.725L149.515 181.143L145.394 193.826L156.183 185.991L166.969 193.826L162.846 181.143L173.637 173.308H160.301L156.183 160.624ZM160.301 219.08L156.183 206.397L152.061 219.08H138.725L149.515 226.916L145.394 239.599L156.183 231.763L166.969 239.599L162.846 226.916L173.637 219.08H160.301ZM216.663 219.08L212.546 206.397L208.423 219.08H195.088L205.877 226.916L201.755 239.599L212.546 231.763L223.331 239.599L219.208 226.916L229.999 219.08H216.663ZM212.546 160.624L208.423 173.308H195.088L205.877 181.143L201.755 193.826L212.546 185.991L223.331 193.826L219.208 181.143L229.999 173.308H216.663L212.546 160.624ZM156.183 114.85L152.061 127.535H138.725L149.515 135.371L145.394 148.054L156.183 140.218L166.969 148.054L162.846 135.371L173.637 127.535H160.301L156.183 114.85ZM212.546 114.85L208.423 127.535H195.088L205.877 135.371L201.755 148.054L212.546 140.218L223.331 148.054L219.208 135.371L229.999 127.535H216.663L212.546 114.85Z" fill="#F0F0F0"/>
            </svg>
            <p>EN</p>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }