import { Carousel } from "@material-tailwind/react";
import { Link } from "react-router-dom";

export function SlideHome() {
  return (
    <Carousel autoplay={true} loop={true} autoplayDelay={4000}>
      <div className="bg-image h-full  object-cover w-screen bg-cover bg-center bg-no-repeat animate-fade animate-once animate-duration-[1000ms] animate-delay-[1ms] animate-ease-linear animate-normal">
        <div className="bg-color flex items-center  ">
          <div className="md:px-10 px-4 flex flex-col space-y-6 relative bottom-6">
            <div className="sm:text-4xl text-2xl font-bold text-white w-[320px] sm:w-[520px] uppercase leading-relaxed animate-fade-up animate-once animate-duration-1000  animate-delay-[1ms] animate-normal animate-fill-forwards">
              Quis nostrud exercitation ullamco laboris nisi ut aliquip
            </div>
            <div className="text-lg   w-[320px] sm:w-[520px] text-white animate-fade-up animate-once animate-duration-1000 animate-delay-[1ms] animate-normal ">
              ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
              voluptate ullamco laboris nisi ut aliquip velit esse cillum dolore
              eu fugiat nulla pariatur. 
            </div>
            <Link
              to="/Nos-activites"
              className="bg-white text-[#f87060] text-center hover:text-white hover:bg-[#f87060] w-40 py-3 font-medium text-lg transition delay-150 duration-700 ease-in-out hover:-translate-y-1 hover:scale-110 animate-fade-up animate-once animate-duration-[1200ms] animate-delay-[1ms] animate-ease-linear animate-normal"
            >
              Nos activités
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-image2 h-full  object-cover w-screen bg-cover bg-center bg-no-repeat animate-fade animate-once animate-duration-[1000ms] animate-delay-[1ms] animate-ease-linear animate-normal">
        <div className="bg-color flex items-center  ">
          <div className="md:px-10 px-4 flex flex-col space-y-6 relative bottom-6">
            <div className="sm:text-4xl text-2xl font-bold text-white w-[320px] sm:w-[520px] uppercase leading-relaxed animate-fade-up animate-once animate-duration-1000  animate-delay-[1ms] animate-normal animate-fill-forwards">
              Quis nostrud exercitation ullamco laboris nisi ut aliquip
            </div>
            <div className="text-lg   w-[320px] sm:w-[520px] text-white animate-fade-up animate-once animate-duration-1000 animate-delay-[1ms] animate-normal ">
              ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
              voluptate ullamco laboris nisi ut aliquip velit esse cillum dolore
              eu fugiat nulla pariatur. 
            </div>
            <Link
              to="/Nos-activites"
              className="bg-white text-[#f87060] text-center hover:text-white hover:bg-[#f87060] w-40 py-3 font-medium text-lg transition delay-150 duration-700 ease-in-out hover:-translate-y-1 hover:scale-110 animate-fade-up animate-once animate-duration-[1200ms] animate-delay-[1ms] animate-ease-linear animate-normal"
            >
              Nos activités
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-image3 h-full  object-cover w-screen bg-cover bg-center bg-no-repeat animate-fade animate-once animate-duration-[1000ms] animate-delay-[1ms] animate-ease-linear animate-normal">
        <div className="bg-color flex items-center  ">
          <div className="md:px-10 px-4 flex flex-col space-y-6 relative bottom-6">
            <div className="sm:text-4xl text-2xl font-bold text-white w-[320px] sm:w-[520px] uppercase leading-relaxed animate-fade-up animate-once animate-duration-1000  animate-delay-[1ms] animate-normal animate-fill-forwards">
              Quis nostrud exercitation ullamco laboris nisi ut aliquip
            </div>
            <div className="text-lg   w-[320px] sm:w-[520px] text-white animate-fade-up animate-once animate-duration-1000 animate-delay-[1ms] animate-normal ">
              ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
              voluptate ullamco laboris nisi ut aliquip velit esse cillum dolore
              eu fugiat nulla pariatur. 
            </div>
            <Link
              to="/Nos-activites"
              className="bg-white text-[#f87060] text-center hover:text-white hover:bg-[#f87060] w-40 py-3 font-medium text-lg transition delay-150 duration-700 ease-in-out hover:-translate-y-1 hover:scale-110 animate-fade-up animate-once animate-duration-[1200ms] animate-delay-[1ms] animate-ease-linear animate-normal"
            >
              Nos activités
            </Link>
          </div>
        </div>
      </div>
    </Carousel>
  );
}
