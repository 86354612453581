import React from "react";
import Header from "../../COMPONENTS/Header/Header";
import { NavbarDefault } from "../../COMPONENTS/Navbar/Navbar";
import backAbout from "../../ASSETS/Image/backAbout.png";
import { Mission } from "./Mission";
import img_rejoindre from "../../ASSETS/Image/Activity1.png";
import backimagemenbre from "../../ASSETS/Image/backimgmenbre.png";
import { Link } from "react-router-dom";
import profil1 from "../../ASSETS/Image/Profil1.png";
import profil2 from "../../ASSETS/Image/Profil2.png";
import profil3 from "../../ASSETS/Image/Profil3.png";
import profil4 from "../../ASSETS/Image/Profil4.png";
import profil5 from "../../ASSETS/Image/Profil5.png";
import profil6 from "../../ASSETS/Image/Profil6.png";
import profil7 from "../../ASSETS/Image/Profil7.png";
import profil8 from "../../ASSETS/Image/Profil8.png";
import { GrFacebookOption } from "react-icons/gr";
import { IoIosMail } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";
import Footer from "../../COMPONENTS/Footer/Footer";
import { useState, useEffect } from "react";
import Loader from "../../COMPONENTS/Loader/Loading";
import {Adhesion} from "../Formulaire/Adhesion"

function About() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 1000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loader />
      ) : (
        <>
          <div>
            <Header />
            <NavbarDefault />

            {/* en tete */}
            <div
              className="bg-cover bg-center bg-no-repeat h-[400px] animate-fade animate-once animate-duration-[1000ms] animate-delay-[1ms] animate-ease-linear animate-normal"
              style={{ backgroundImage: `url(${backAbout})` }}
            >
              <div className="bg-[#2e405725] flex justify-center items-center h-[400px]  ">
                <div className="sm:text-4xl md:px-10 px-4  text-2xl font-bold text-white  uppercase leading-relaxed animate-fade-up animate-once animate-duration-1000 animate-delay-[1ms] animate-normal">
                  À propos de nous
                </div>
              </div>
            </div>

            {/* Qui somme nous ? */}
            <div className="Animation-option mt-20 flex flex-col md:flex-row md:space-x-28 md:space-y-0 space-y-12 justify-center s:px-4 md:px-0">
              <div className="flex flex-col space-y-3 ">
                <div className="text-white text-sm bg-[#f87060] w-36 text-center py-1 rounded-full">
                  Qui sommes-nous
                </div>
                <p className="text-3xl font-semibold text-[#2e4057]">
                  Nom de l’association
                </p>
                <div className="sm:w-[500px] leading-loose">
                  We aim to simplify complex processes, enhance operational
                  efficiency, and drive growth through our innovative software
                  and services. By providing reliable solutions, we enable our
                  clients to navigate the ever-changing tech landscape. eliable
                  solutions, we enable our clients to navigate the ever-changing
                  tech landscape.
                </div>
              </div>
              <div className="sm:w-[500px]">
                <Mission />
              </div>
            </div>

            {/* Nous rejoindre */}
            <div className="Animation-option mt-20 bg-[#FEF8E7] p-10 flex md:flex-row flex-col justify-center md:space-x-20 md:space-y-0 space-y-14">
              {/* image description */}
              <div className="relative overflow-hidden sm:w-[451px] sm:h-[435px]  ">
                <img
                  src={img_rejoindre}
                  className="w-full h-full  object-cover"
                  alt="rejoindre"
                />
              </div>

              {/* Description texte */}
              <div>
                <p className="text-3xl font-semibold text-[#2e4057] uppercase">
                  Pourquoi nous rejoindre ?
                </p>
                <div className="h-1 w-20 bg-[#f87060] mt-4"></div>
                <div className="sm:w-[500px] w-[320px] leading-loose mt-4">
                  Integer vitae justo eget magna fermentum iaculis. Mattis
                  rhoncus urna neque viverra. Nisi porta lorem mollis aliquam ut
                  porttitor leo a diam. Dictum fusce ut placerat orci nulla
                  pellentesque.
                  <br />
                  <br />
                  Aenean euismod elementum nisi quis eleifend quam.Malesuada
                  fames ac turpis egestas maecenas pharetra convallis posuere
                  morbi. Morbi tristique senectus et netus et malesuada fames ac
                  turpis. <br />
                  <br />
                  Sed blandit libero volutpat sed cras ornare. Cras adipiscing
                  enim eu turpis egestas pretium aenean pharetra magna ....
                </div>
                <div className="mt-4">
                  <Adhesion />
                </div>
              </div>
            </div>

            {/* Nos membres */}
            <div className="mt-20 Animation-option">
              <div
                className="bg-cover bg-center bg-no-repeat h-[140px]"
                style={{ backgroundImage: `url(${backimagemenbre})` }}
              >
                <div className="bg-color-white h-[340px] md:px-10 px-5 pt-6">
                  <div className="text-2xl uppercase text-[#f87060] font-bold">
                    Les membres de notre équipe
                  </div>
                </div>
              </div>
              {/* quelques membres */}
              <div className="grid sm:grid-cols-4  gap-y-8  mt-12 place-content-center place-items-center">
                {/* membre1 */}
                <div>
                  <Link to="/A-propos/membre">
                    <div className="photo-membre">
                      <img src={profil1} alt="pp1" />
                    </div>
                  </Link>
                  <div className="flex flex-col items-center space-y-2 w-[206px] p-3 pb-6 bg-white shadow">
                    <p className=" font-medium text-[#f87060] text-center">
                      Presidente
                    </p>
                    <p className="text-lg font-semibold text-[#4e4e4e] text-center">
                      Touré Victoria
                    </p>
                    {/* reseaux sociaux */}
                    <div className="flex flex-row items-center space-x-2 pt-3">
                      <Link to="">
                        <GrFacebookOption className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <TiSocialLinkedin className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <IoIosMail className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <FaInstagram className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* membre2 */}
                <div>
                  <div className="photo-membre">
                    <img src={profil2} alt="pp1" />
                  </div>
                  <div className="flex flex-col items-center space-y-2 w-[206px] p-3 pb-6 bg-white shadow">
                    <p className=" font-medium text-[#f87060] text-center">
                      Directeur
                    </p>
                    <p className="text-lg font-semibold text-[#4e4e4e] text-center">
                      Kouakou ange christ
                    </p>
                    {/* reseaux sociaux */}
                    <div className="flex flex-row items-center space-x-2 pt-3">
                      <Link to="">
                        <GrFacebookOption className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <TiSocialLinkedin className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <IoIosMail className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <FaInstagram className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* membre3 */}
                <div>
                  <div className="photo-membre">
                    <img src={profil3} alt="pp1" />
                  </div>
                  <div className="flex flex-col items-center space-y-2 w-[206px] p-3 pb-6 bg-white shadow">
                    <p className=" font-medium text-[#f87060] text-center">
                      Assistante
                    </p>
                    <p className="text-lg font-semibold text-[#4e4e4e] text-center">
                      koudou hermine
                    </p>
                    {/* reseaux sociaux */}
                    <div className="flex flex-row items-center space-x-2 pt-3">
                      <Link to="">
                        <GrFacebookOption className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <TiSocialLinkedin className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <IoIosMail className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <FaInstagram className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* membre4 */}
                <div>
                  <div className="photo-membre">
                    <img src={profil4} alt="pp1" />
                  </div>
                  <div className="flex flex-col items-center space-y-2 w-[206px] p-3 pb-6 bg-white shadow">
                    <p className=" font-medium text-[#f87060] text-center">
                      Interprete
                    </p>
                    <p className="text-lg font-semibold text-[#4e4e4e] text-center">
                      Yao christianah
                    </p>
                    {/* reseaux sociaux */}
                    <div className="flex flex-row items-center space-x-2 pt-3">
                      <Link to="">
                        <GrFacebookOption className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <TiSocialLinkedin className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <IoIosMail className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <FaInstagram className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* membre5 */}
                <div>
                  <div className="photo-membre">
                    <img src={profil5} alt="pp1" />
                  </div>
                  <div className="flex flex-col items-center space-y-2 w-[206px] p-3 pb-6 bg-white shadow">
                    <p className=" font-medium text-[#f87060] text-center">
                      Interprete
                    </p>
                    <p className="text-lg font-semibold text-[#4e4e4e] text-center">
                      Yao christianah
                    </p>
                    {/* reseaux sociaux */}
                    <div className="flex flex-row items-center space-x-2 pt-3">
                      <Link to="">
                        <GrFacebookOption className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <TiSocialLinkedin className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <IoIosMail className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <FaInstagram className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* membre6 */}
                <div>
                  <div className="photo-membre">
                    <img src={profil6} alt="pp1" />
                  </div>
                  <div className="flex flex-col items-center space-y-2 w-[206px] p-3 pb-6 bg-white shadow">
                    <p className=" font-medium text-[#f87060] text-center">
                      Interprete
                    </p>
                    <p className="text-lg font-semibold text-[#4e4e4e] text-center">
                      Yao christianah
                    </p>
                    {/* reseaux sociaux */}
                    <div className="flex flex-row items-center space-x-2 pt-3">
                      <Link to="">
                        <GrFacebookOption className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <TiSocialLinkedin className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <IoIosMail className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <FaInstagram className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* membre7 */}
                <div>
                  <div className="photo-membre">
                    <img src={profil7} alt="pp1" />
                  </div>
                  <div className="flex flex-col items-center space-y-2 w-[206px] p-3 pb-6 bg-white shadow">
                    <p className=" font-medium text-[#f87060] text-center">
                      Interprete
                    </p>
                    <p className="text-lg font-semibold text-[#4e4e4e] text-center">
                      Yao christianah
                    </p>
                    {/* reseaux sociaux */}
                    <div className="flex flex-row items-center space-x-2 pt-3">
                      <Link to="">
                        <GrFacebookOption className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <TiSocialLinkedin className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <IoIosMail className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <FaInstagram className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* membre8 */}
                <div>
                  <div className="photo-membre">
                    <img src={profil8} alt="pp1" />
                  </div>
                  <div className="flex flex-col items-center space-y-2 w-[206px] p-3 pb-6 bg-white shadow">
                    <p className=" font-medium text-[#f87060] text-center">
                      Interprete
                    </p>
                    <p className="text-lg font-semibold text-[#4e4e4e] text-center">
                      Yao christianah
                    </p>
                    {/* reseaux sociaux */}
                    <div className="flex flex-row items-center space-x-2 pt-3">
                      <Link to="">
                        <GrFacebookOption className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <TiSocialLinkedin className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <IoIosMail className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                      <Link to="">
                        <FaInstagram className="text-xl text-[#4e4e4e] bg-[#d9d9d9] hover:bg-[#2e4057] hover:text-white w-6 h-6 rounded-full p-1" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-20">
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default About;
